<template>
  <div class="layoutContainer">
    <div class="secondFloat">
      <el-form :inline="true" ref="formInline" :model="formInline" class="demo-form-inline">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="formInline.projectName" placeholder="请输入项目名称"></el-input>
        </el-form-item>
        <el-form-item label="负责人" prop="director">
          <el-select v-model="formInline.director">
            <el-option label="请选择" value="-请选择-"></el-option>
            <el-option v-for="(item, index) of principalList" :key="index" :label="item.realName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目状态" prop="projectStatus">
          <el-select v-model="formInline.projectStatus">
            <el-option label="进行中" value="1"></el-option>
            <el-option label="暂停中" value="2"></el-option>
            <el-option label="已验收" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="立项时间范围">
          <el-date-picker type="date" placeholder="选择开始日期" v-model="formInline.begintime"></el-date-picker>
          <span class="line">至</span>
          <el-date-picker type="date" placeholder="选择结束日期" v-model="formInline.endtime"></el-date-picker>
        </el-form-item>
        <el-form-item label="车型" prop="modelCode">
          <el-input v-model="formInline.modelCode" placeholder="请输入车型"></el-input>
        </el-form-item>
        <el-form-item label="年款" prop="modelYear">
          <el-input v-model="formInline.modelYear" placeholder="请输入年款"></el-input>
        </el-form-item>
        <el-form-item label="风险状态" prop="riskStatus">
          <el-select v-model="formInline.riskStatus">
            <el-option label="无风险" value="1"></el-option>
            <el-option label="低风险" value="2"></el-option>
            <el-option label="中风险" value="3"></el-option>
            <el-option label="高风险" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit" icon="el-icon-search">搜索</el-button>
          <el-button plain @click="reset('formInline')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tableDetail">
      <el-table style="width:100%" border :data="resultList" :header-cell-style="{'text-align':'center'}" :cell-style="{'text-align':'center'}">
        <el-table-column label="序号" type="index" width="60"></el-table-column>
        <el-table-column label="项目名称" prop="name" width="190"></el-table-column>
        <el-table-column label="负责人" prop="principalName" width="190"></el-table-column>
        <el-table-column label="项目状态" prop="projectStatus" width="140">
          <template slot-scope="{row}">
            <span v-if="row.projectStatus === 1">进行中</span>
            <span v-if="row.projectStatus === 2">暂停中</span>
            <span v-if="row.projectStatus === 3">已验收</span>
          </template>
        </el-table-column>
        <el-table-column label="车型" prop="modelCode" width="140"></el-table-column>
        <el-table-column label="年款" prop="modelYear" width="130"></el-table-column>
        <el-table-column label="立项时间" prop="establishTime" width="190" :formatter="dateFormat"></el-table-column>
        <el-table-column label="节点时间" prop="deadlineTime" width="190" :formatter="dateFormat"></el-table-column>
        <el-table-column label="风险状态" prop="riskStatus" width="130">
          <template slot-scope="{row}">
            <span v-if="row.riskStatus === 1" style="color:#009933">无风险</span>
            <span v-if="row.riskStatus === 2" style="color:#cc0000">低风险</span>
            <span v-if="row.riskStatus === 3" style="color:#cc0000">中风险</span>
            <span v-if="row.riskStatus === 4" style="color:#cc0000">高风险</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
           <template slot-scope="{row}">
            <el-button type="primary" size="mini" @click="headerDetail(row)">查看</el-button>
            <el-button type="primary" size="mini" @click="headelIssue(row, '发布设置', '/config')">设置</el-button>
            <el-button type="primary" size="mini" @click="headelIssue(row, '发布管理', '/release')">发布</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="total>0" :total="total" :page.sync="currentPage" :limit.sync="pageSize" @pagination="dataList"/>
      <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" :before-close="handleCloseCreate">
        <el-form v-if="dialogStatus === 'add' || dialogStatus === 'edit'" :model="temp" ref="temp" label-position="center" :rules="rules">
          <el-form-item label="解析规则" prop="defaultParse" :label-width="formLabelWidth">
            <el-select v-model="temp.defaultParse">
              <el-option v-for="(item, index) in manualParseList" :key="index" :label="item.name" :value="item.code"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="输出类型" prop="outputType" :label-width="formLabelWidth">
            <el-select v-model="temp.outputType">
              <el-option value="html" label="html"></el-option>
              <el-option value="pdf" label="pdf"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="有效状态" prop="useFlag" :label-width="formLabelWidth">
            <el-select v-model="temp.useFlag">
              <el-option value="1" label="启动"></el-option>
              <el-option value="2" label="禁用"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-button type="primary" @click="addClick('temp')">立即提交</el-button>
            <el-button @click="resetForm('temp')">重置</el-button>
          </el-form-item>
        </el-form>
        <el-form v-if="dialogStatus === 'detail'" :model="temp" ref="temp" label-position="center">
          <el-form-item label="公司" prop="firmId" :label-width="formLabelWidth">
            <el-select v-model="temp.firmId" readonly>
              <el-option v-for="(item, index) of firmList" :key="index" :label="item.nameCh" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="项目名称" prop="name" :label-width="formLabelWidth">
            <el-input v-model="temp.name" readonly></el-input>
          </el-form-item>
          <el-form-item label="负责人" prop="principal" :label-width="formLabelWidth">
            <el-input v-model="temp.principalName" readonly></el-input>
          </el-form-item>
          <el-form-item label="立项时间" prop="establishTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.establishTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="项目节点" prop="deadlineTime" :label-width="formLabelWidth">
            <el-date-picker type="date" v-model="temp.deadlineTime" readonly></el-date-picker>
          </el-form-item>
          <el-form-item label="项目状态" prop="projectStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.projectStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="风险状态" prop="riskStatus" :label-width="formLabelWidth">
            <el-input v-model="temp.riskStatus" readonly></el-input>
          </el-form-item>
          <el-form-item label="车型" prop="modelCode" :label-width="formLabelWidth">
            <el-input v-model="temp.modelCode" readonly></el-input>
          </el-form-item>
          <el-form-item label="年款" prop="modelYear" :label-width="formLabelWidth">
            <el-input v-model="temp.modelYear" readonly></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remark" :label-width="formLabelWidth">
            <el-input v-model="temp.remark" readonly></el-input>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { sysServerUrl, cmsServerUrl, obj } from '@/assets/js/common.js'
import Pagination from '@/components/Pagination'
import { issueData, issueSearch, issueUser, issueProject, issueFirm, issueManual, manualParseList, issueAdd } from '@/api/api.js'
import axios from 'axios'
export default {
  name: 'layoutContainer',
  components: { Pagination },
  data () {
    return {
        formInline: {
        projectName: '',
        director: '',
        projectStatus: '',
        begintime: '',
        endtime: '',
        modelCode: '',
        modelYear: '',
        riskStatus: ''
      },
      temp: {
        id: '',
        firmId: '',
        name: '',
        principal: '',
        establishTime: '',
        deadlineTime: '',
        projectStatus: '',
        riskStatus: '',
        modelCode: '',
        remark: ''
      },
      riskList: [
        { name: '无风险', code: 1 },
        { name: '低风险', code: 2 },
        { name: '中风险', code: 3 },
        { name: '高风险', code: 4 }
      ],
      projectList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      time: {
        deadlineTime: '',
        firstDraftTime: '',
        finalDraftTime: ''
      },
      manualList: [
        { name: '未开始', code: 1 },
        { name: '进行中', code: 2 },
        { name: '已完成', code: 3 }
      ],
      projectStatusList: [
        { name: '进行中', code: 1 },
        { name: '暂停中', code: 2 },
        { name: '已验收', code: 3 }
      ],
      principalList: [],
      manualTypeList: [],
      manualParseList: [],
      dialogFormVisible: false,
      dialogStatus: '',
      manualType: '',
      textMap: {
        detail: '查看',
        add: '新增'
      },
      formLabelWidth: '100px',
      resultList: [],
      pageSize: 10,
      currentPage: 1,
      total: 0,
      rules: {
        defaultParse: [{ required: true, message: '请选择解析规则', tigger: 'blur' }],
        outputType: [{ required: true, message: '请选择输出类型', trigger: 'blur' }],
        useFlag: [{ required: true, message: '请选择有效状态', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 数据
    dataList () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize
      }
      issueData(params).then(res=>{
        this.total = res.data.total
        this.resultList = res.data.data
      })
    },
    // 负责人
    getUserList () {
      issueUser().then(res => {
        if (res.data.code === 100) {
          this.principalList = res.data.data
        }
      })
    },
    // 获取项目列表
    getProjectList () {
      issueProject().then(res => {
        if (res.data.code === 100) {
          this.projectList = res.data.data
        }
      })
    },
    // 公司
    getFirmList () {
      issueFirm().then(res=>{
        if (res.data.code == 100) {
          this.firmList = res.data.data
        }
      })
    },
    // 获取手册分类列表
    getManualList () {
      issueManual().then(res => {
        if (res.data.code === 100) {
          this.manualTypeList = res.data.data
        }
      })
    },
    // 手册解析规则列表
    getManualParseList () {
      var params = '?userFlag=1&manualType' + this.manualType
      manualParseList(params).then(res => {
        if (res.data.code === 100) {
          this.manualParseList = res.data.data
        }
      })
    },
    handleCloseCreate () {
      this.dialogFormVisible = false
      // if (this.dialogStatus === 'add') {
      //   this.$refs.temp.resetFields()
      // }
    },
    // 提交
    onSubmit () {
      var params = {
        page: this.currentPage,
        limit: this.pagesize,
        name: this.formInline.projectName,
        principal: this.formInline.director,
        projectStatus: this.formInline.projectStatus,
        establishTime: this.formInline.begintime,
        deadlineTime: this.formInline.endtime,
        modelCode: this.formInline.modelCode,
        modelYear: this.formInline.modelYear,
        riskStatus: this.formInline.riskStatus
      }
      issueSearch(params).then(res => {
        this.total = res.data.total
        this.resultList = res.data.data
      })
      this.dataList()
    },
    // 重置
    reset (formInline) {
      if (this.$refs[formInline].resetFields() !== undefined) {
        this.$refs[formInline].resetFields()
      }
      this.dataList()
    },
    resetTemp () {
      this.temp = {
        manualId: '',
        manualVersion: '',
        projectId: '',
        defaultParse: '',
        outputType: '',
        useFlag: ''
      }
    },
  
    addClick (temp) {
      this.$refs[temp].validate((valid) => {
        if (valid) {
          var params = new URLSearchParams()
          params.append('manualId', this.temp.manualId)
          params.append('manualVersion', this.temp.manualVersion)
          params.append('parseCode', this.temp.parseCode)
          params.append('outputType', this.temp.outputType)
          params.append('useFlag', this.temp.useFlag)
          params.append('remark', this.temp.remark)
          issueAdd(params).then(res => {
            if (res.data.code === 100) {
              this.$message({
                message: res.data.msg,
                type: 'success'
              })
              this.dataList()
              this.dialogFormVisible = false
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(err => {
            if (err !== null && err !== '' && err.responseText !== null) {
              this.$message.error('提交失败,请重试')
            }
          })
        } else {
          this.$message.error('完善信息')
        }
      })
    },
    resetForm (temp) {
      if (this.$refs[temp].resetFields() !== undefined) {
        this.$refs[temp].resetFields()
      }
    },
    // 查看
    headerDetail (row) {
      this.resetTemp()
      this.temp = Object.assign({}, row)
      this.getStatusCode()
      this.dialogFormVisible = true
      this.dialogStatus = 'detail'
    },
    // 发布  设置
    headelIssue (row, name, url) {
      let title = row.name+'#'+name
      let purl = url+'/'+row.id
      var name = url.split('/')[1]
      console.log(name)
      this.$router.push({ name: name, params:{ projectId: row.id }})
      // let  purl= url +"?projectId="+row.id
      obj.tabsClick(title, purl)
      // this.$router.push({ path: purl})

      //obj.tabsClick(name, url)
      //this.$router.push({ path: url, query: { manualId: row.id, manualVersion: row.manualVersion, manualType: row.manualType, manualName: row.cnName, projectName: row.projectName } })
    },
    // 风险状态
    getStatusCode () {
      for (var i = 0; i < this.firmList.length; i++) {
        if (this.firmList[i].id === this.temp.firmId) {
          this.temp.firmId = this.firmList[i].nameCh
        }
      }
      for (var j = 0; j < this.riskList.length; j++) {
        if (this.riskList[j].code === this.temp.riskStatus) {
          this.temp.riskStatus = this.riskList[j].name
        }
      }
      for (var k = 0; k < this.projectStatusList.length; k++) {
        if (this.projectStatusList[k].code === this.temp.projectStatus) {
          this.temp.projectStatus = this.projectStatusList[k].name
        }
      }
    },
    // 时间转换
    dateFormat (row, column, cellValue, index) {
      if (cellValue !== null) {
        const date = new Date(parseInt(cellValue))
        const Y = date.getFullYear() + '-'
        const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-'
        const D = date.getDate() < 10 ? '0' + date.getDate() + '' : date.getDate() + ''
        return Y + M + D
      }
    }
  },
  mounted () {
    // obj.areaSize()
    this.dataList()
    this.getUserList()
    this.getProjectList()
    this.getFirmList()
    this.getManualList()
    this.getManualParseList()
  }
}
</script>
<style>
  .el-input.is-disabled .el-input__inner::placeholder {
    color: transparent
  }
  .el-input.is-disabled .el-input__inner {
    background-color: transparent;
    border: 1px solid #DCDFE6;
    color: #606266;
    cursor: text !important;
  }
  .el-input.is-disabled .el-input__icon {
    cursor: text !important;
  }
</style>
